import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";

const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
});

const StyledCardMedia = styled(CardMedia)({
  paddingTop: "56.25%", // 16:9 aspect ratio
  backgroundSize: "contain",
  backgroundColor: "#f5f5f5",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledTypography = styled(Typography)({
  color: "#000000",
});

const BlackDivider = styled(Divider)({
  backgroundColor: "rgba(0, 0, 0, 0.12)",
});

const CardItem = ({
  icon,
  title,
  price,
  originalPrice,
  features,
  buttonLabel,
}) => {
  return (
    <StyledCard>
      <StyledCardMedia image={icon} title={title} />
      <StyledCardContent>
        <StyledTypography
          gutterBottom
          variant="h5"
          component="h2"
          align="center"
          fontWeight="bold"
          style={{ color: "#1976d2" }}
        >
          {title}
        </StyledTypography>
        <Box sx={{ my: 2 }}>
          {price && (
            <StyledTypography
              variant="h4"
              component="p"
              align="center"
              fontWeight="bold"
              style={{ color: "#f50057" }}
            >
              {originalPrice && (
                <StyledTypography
                  variant="body2"
                  style={{ textDecoration: "line-through", color: "#666666" }}
                >
                  ₹{originalPrice}
                </StyledTypography>
              )}
              ₹{price}
            </StyledTypography>
          )}
          {!price && (
            <StyledTypography
              variant="h4"
              component="p"
              align="center"
              fontWeight="bold"
              style={{ color: "#f50057" }}
            >
              Contact Us
            </StyledTypography>
          )}
        </Box>
        <BlackDivider sx={{ my: 2 }} />
        <Box sx={{ flexGrow: 1 }}>
          {features &&
            features.map((feature, index) => (
              <React.Fragment key={index}>
                <StyledTypography
                  variant="body1"
                  align="center"
                  paragraph
                  style={{ color: "#000000", fontWeight: 500 }}
                >
                  {feature}
                </StyledTypography>
                {index < features.length - 1 && <BlackDivider sx={{ my: 1 }} />}
              </React.Fragment>
            ))}
        </Box>
      </StyledCardContent>
      <Button
        component={RouterLink}
        to="/register"
        variant="contained"
        color="primary"
        size="large"
        color="primary"
        sx={{ m: 2 }}
      >
        {buttonLabel || "Start Trial"}
      </Button>
    </StyledCard>
  );
};

export default CardItem;
