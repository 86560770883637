import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, List, ListItem, ListItemText, Paper } from '@mui/material';

const CompanySearch = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 1) {
        try {
          const response = await axios.get(`/api/search_companies?query=${query}`);
          setSuggestions(response.data);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
      }
    };

    const timeoutId = setTimeout(fetchSuggestions, 300);
    return () => clearTimeout(timeoutId);
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setSelectedCompany(null);
  };

  const handleSuggestionClick = (company) => {
    setQuery(company.name);
    setSelectedCompany(company);
    setSuggestions([]);
  };

  return (
    <div>
      <TextField
        fullWidth
        label="Search companies"
        variant="outlined"
        value={query}
        onChange={handleInputChange}
      />
      {suggestions.length > 0 && (
        <Paper>
          <List>
            {suggestions.map((company) => (
              <ListItem
                button
                key={company.id}
                onClick={() => handleSuggestionClick(company)}
              >
                <ListItemText primary={`${company.name} (${company.ticker})`} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {selectedCompany && (
        <p>Selected: {selectedCompany.name} (ID: {selectedCompany.id}, Ticker: {selectedCompany.ticker})</p>
      )}
    </div>
  );
};

export default CompanySearch;