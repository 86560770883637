import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import analyticsImage from "../assets/analytics.jpg"; // Import the image

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(8, 0),
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  height: "auto",
  margin: "0 auto",
  display: "block",
}));

const Analytics = () => {
  return (
    <StyledBox>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <StyledImage
              src={analyticsImage} // Use the imported image
              alt="data analytics"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="subtitle1"
                color="primary"
                fontWeight="bold"
                gutterBottom
              >
                AI ANALYTICS DASHBOARD
              </Typography>
              <Typography
                variant="h3"
                component="h2"
                fontWeight="bold"
                gutterBottom
              >
                AI based insights at your fingertips
              </Typography>
              <Typography variant="body1" paragraph>
                Do you find concalls and announcements hard to manage? Access
                our AI based insights dashboard to skim through the latest
                announcements and conference calls in minutes.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{
                  mt: 2,
                  px: 4,
                  py: 1,
                }}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Analytics;
