import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: theme.spacing(12, 0), // Increased vertical padding
  minHeight: "100vh", // Ensure it takes at least full viewport height
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  overflow: "hidden",
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  paddingTop: "66.67%", // 3:2 aspect ratio for larger images
  [theme.breakpoints.up("md")]: {
    paddingTop: "75%", // 4:3 aspect ratio for even larger images on desktop
  },
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
}));

const FeatureBlock = ({ title, description, buttonText, imageUrl }) => (
  <StyledCard>
    <StyledCardMedia image={imageUrl} title={title} />
    <StyledCardContent>
      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        sx={{ color: "rgba(0, 0, 0, 0.87)" }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: "rgba(0, 0, 0, 0.6)" }}
        component="p"
      >
        {description}
      </Typography>
      {buttonText && (
        <Button
          component={RouterLink}
          to="/register"
          variant="contained"
          color={buttonText === "Coming Soon" ? "secondary" : "primary"}
          sx={{
            mt: 2,
            backgroundColor:
              buttonText === "Coming Soon" ? "rgba(0, 0, 0, 0.38)" : undefined,
            color:
              buttonText === "Coming Soon"
                ? "rgba(255, 255, 255, 0.87)"
                : undefined,
            "&:hover": {
              backgroundColor:
                buttonText === "Coming Soon"
                  ? "rgba(0, 0, 0, 0.48)"
                  : undefined,
            },
          }}
        >
          {buttonText}
        </Button>
      )}
    </StyledCardContent>
  </StyledCard>
);

const Features = () => {
  const features = [
    {
      title: "Search like Never Before",
      description:
        "Search in natural language to identify companies. Example: 'textile companies supplying to Australian clothing brands providing positive revenue guidance'",
      buttonText: "Try Now",
      imageUrl: "/concal.jpg",
    },
    {
      title: "Chat with our Financial AI Agent",
      description:
        "To learn in detail about any investment class, equity sector, financial planning, we have everything covered.",
      buttonText: "Coming Soon",
      imageUrl: "/ai_agent.jpg",
    },
    {
      title: "Deep Dive in Companies",
      description:
        "Contextual information like never before whether management guidance, sentiment, management ratings.",
      buttonText: "Coming Soon",
      imageUrl: "/deepdive.jpg",
    },
  ];

  return (
    <StyledBox>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          AI-Powered Financial Analytics
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <FeatureBlock {...feature} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Features;
