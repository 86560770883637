import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}));

const StyledImage = styled("img")({
  width: "200px",
  margin: "0 auto",
  marginBottom: "2rem",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
  [theme.breakpoints.up("md")]: {
    textShadow: "3px 3px 6px rgba(0,0,0,0.5)",
  },
}));

const Hero = () => {
  return (
    <StyledBox>
      <Container maxWidth="md">
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Grid item>
            <StyledImage src="/image_v11.png" alt="Bull vs Bear" />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="bold"
              align="center"
            >
              Discover Hidden Information
            </Typography>
          </Grid>
          <Grid item>
            <StyledTypography variant="h2" fontWeight="bold" align="center">
              Search across thousands of companies
            </StyledTypography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="text.secondary" align="center">
              From ideas to buying stock within minutes powered by our AI search
              engine and insights dashboard.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={RouterLink}
              to="/register"
              variant="contained"
              color="primary"
              size="large"
              sx={{
                px: 6,
                py: 1.5,
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(255, 255, 255, 0.1)",
                  transition: "all 0.3s ease",
                },
                "&:hover::after": {
                  background: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Hero;
