import React from "react";
import Hero from "./Hero";
import Features from "./Features";
import Analytics from "./Analytics";
import Cards from "./Cards/Cards";

const Home = () => (
  <>
    <section id="home">
      <Hero />
    </section>
    <section id="features">
      <Features />
    </section>
    <section id="demo">
      <Analytics />
    </section>
    <section id="pricing">
      <Cards />
    </section>
  </>
);

export default Home;
