// src/components/SearchPage.jsx
import React from "react";
import { Container, Typography } from "@mui/material";
import CompanySearch from "./CompanySearch";

const SearchPage = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Company Search
      </Typography>
      <CompanySearch />
    </Container>
  );
};

export default SearchPage;
