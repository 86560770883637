import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container mx-auto py-16 px-4 grid lg:grid-cols-3 gap-20 text-gray-300">
        <div>
          <h1 className="w-full text-3xl font-bold text-primary">
            compounding-ai
          </h1>
          <p className="py-4">
            From ideas to buying stock within minutes powered by our AI search
            engine and insights dashboard.
          </p>
          <div className="flex justify-between md:w-1/1 my-1">
            <FaInstagram size={30} className="footer-icon" />
            <FaTwitterSquare size={30} className="footer-icon" />
          </div>
        </div>
        <div className="mt-6 grid grid-cols-footer gap-4 lg:justify-items-center lg:col-span-2 lg:gap-0">
          <div>
            <h6 className="font-medium text-gray-400 mb-1">Solutions</h6>
            <ul>
              <li className="footer-list">Search</li>
              <li className="footer-list">Deep-Dive</li>
              <li className="footer-list">FinAI Agent</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
