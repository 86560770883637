import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import CardItem from "./CardItem";
import Best from "../../assets/best.png";

const Cards = () => {
  return (
    <Box sx={{ bgcolor: "background.paper", py: 8 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h2"
          align="center"
          gutterBottom
          fontWeight="bold"
        >
          Our Plans
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
        >
          Choose the plan that fits your needs
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem
              icon={Best}
              title="Monthly Plan"
              originalPrice="600"
              price="400"
              features={[
                "500 search queries per month",
                "1 Granted User",
                "200 companies deep-dive per month",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem
              icon={Best}
              title="Yearly Plan"
              originalPrice="7200"
              price="3600"
              features={[
                "1000 search queries per month",
                "1 Granted User",
                "200 companies deep-dive per month",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem
              icon={Best}
              title="Yearly Plan"
              originalPrice="12000"
              price="6000"
              features={[
                "4000 search queries per month",
                "3 Granted User",
                "600 companies deep-dive per month",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardItem
              icon={Best}
              title="Enterprise Plan"
              features={["Custom Queries", "Custom Users", "Custom Deep-Dive"]}
              buttonLabel="Contact Us"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Cards;
